
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Store from "@/store/Store";
import NetworkGraphCard from "@/components/visual-navigator/network-graph-card.vue";
import SimulationBadge from "@/components/simulation-badge.vue";
import {
  BBreadcrumb,
  BIconList,
  BButton,
  BIconChevronDoubleLeft,
  BFormCheckbox,
  BIconFullscreen,
  BIconFullscreenExit,
  VBTooltip,
} from "bootstrap-vue";
import Graph from "@/components/visual-navigator/graph/graph.vue";
import GraphLegend from "@/components/visual-navigator/graph/graph-legend.vue";
import ClientOnly from "vue-client-only";

@Component({
  name: "network-visual-navigator",
  components: {
    GraphLegend,
    Graph,
    SimulationBadge,
    NetworkGraphCard,
    "world-map": () => import("@/components/visual-navigator/world-map.vue"),
    BBreadcrumb,
    BIconList,
    BButton,
    BIconChevronDoubleLeft,
    BFormCheckbox,
    BIconFullscreen,
    BIconFullscreenExit,
    ClientOnly,
  },
  directives: { "b-tooltip": VBTooltip },
})
export default class NetworkVisualNavigator extends Vue {
  @Prop({ default: "map" })
  public view!: string;

  public optionShowFailingEdges = false;
  public optionHighlightTrustingNodes = true;
  public optionHighlightTrustedNodes = true;
  public optionShowRegularEdges = true;
  public optionTransitiveQuorumSetOnly = false;

  protected menuVisible = false;
  protected fullScreen = false;

  get breadCrumbs() {
    let crumbs = [];
    crumbs.push({
      text: this.store.getNetworkIdPretty(),
      to: {
        name: "network-dashboard",
        query: {
          view: this.$route.query.view,
          network: this.$route.query.network,
          at: this.$route.query.at,
        },
      },
    });

    if (this.selectedNode) {
      if (
        this.selectedNode.organizationId &&
        this.network.getOrganizationById(this.selectedNode.organizationId)
      )
        crumbs.push({
          text: this.network.getOrganizationById(
            this.selectedNode.organizationId
          ).name,
          to: {
            name: "organization-dashboard",
            params: {
              organizationId: this.selectedNode.organizationId,
            },
            query: {
              view: this.$route.query.view,
              network: this.$route.query.network,
              at: this.$route.query.at,
            },
          },
          active: false,
        });
      crumbs.push({
        text: this.selectedNode.displayName,
        active: true,
      });
    } else if (this.selectedOrganization)
      crumbs.push({
        text: this.selectedOrganization.name,
        active: true,
      });
    return crumbs;
  }

  navigateToView() {
    let toView = this.view === "map" ? "graph" : "map";
    this.$router.push({
      name: this.$route.name ? this.$route.name : undefined,
      params: this.$route.params,
      query: {
        view: toView,
        "no-scroll": "1",
        network: this.$route.query.network,
        at: this.$route.query.at,
      },
    });
  }

  get store(): Store {
    return this.$root.$data.store;
  }

  get selectedNode() {
    return this.store.selectedNode;
  }

  get centerNode() {
    return this.store.centerNode;
  }

  get selectedOrganization() {
    return this.store.selectedOrganization;
  }

  get network() {
    return this.store.network;
  }
}
