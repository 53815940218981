
import { BIconExclamationTriangle, VBTooltip } from "bootstrap-vue";
import Vue from "vue";

Vue.component("b-icon-exclamation-triangle", BIconExclamationTriangle);
Vue.directive("b-tooltip", VBTooltip);
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    hasWarnings: {
      type: Boolean,
      default: false,
    },
    warnings: {
      type: String,
      default: "",
    },
    hasDanger: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: String,
      default: "",
    },
    completeDanger: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
