
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Store from "@/store/Store";
import UndoRedo from "@/components/node/tools/simulation/UndoRedo.vue";
import stickybits from "stickybits";
import {
  BIcon,
  BFormCheckbox,
  BIconHouse,
  BIconBullseye,
  BIconBuilding,
} from "bootstrap-vue";

@Component({
  components: {
    UndoRedo,
    BIcon,
    BFormCheckbox,
    BIconHouse,
    BIconBullseye,
    BIconBuilding,
  },
})
export default class SideBar extends Vue {
  @Prop()
  stickyKey!: string;

  @Prop()
  icon!: string;

  @Prop({ default: true })
  hasExploreSection!: boolean;

  get store(): Store {
    return this.$root.$data.store;
  }

  get selectedNode() {
    return this.store.selectedNode;
  }

  get network() {
    return this.store.network;
  }

  mounted() {
    stickybits("#sticky");
  }
}
