
import Store from "@/store/Store";
import { BIconX } from "bootstrap-vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { BIconX: BIconX },
})
export default class SimulationBadge extends Vue {
  get store(): Store {
    return this.$root.$data.store;
  }
}
